.contenedor{
    position: relative;
    margin-top: 7.31em;
    margin-bottom: 7.31em;
    font-size: 1vw;
}
.contenedorCondiciones{

 
     padding: 8.48em;
     display: grid;
     grid-template-columns: repeat(2, 1fr);
     position: relative;

}

.cartel{
    position: absolute;
left: 25.58vw;



display: flex;


}
.cartelIzquierda{
    position: absolute;
    top: 1.24em;
    left: -1.61em;
    width: 3.51em;
    height: 3.51em;
    border: 0.29em dashed #767676;
    color: #3C3C3A;
    transform: rotate(55deg) skewX(20deg);
    border-bottom: 0px;
    border-left: 0px;
}

.cartelCentro{
    width: 14.3em;
    height: 1.6em;
    border: 0.08em dashed #767676;
    color: #3C3C3A;
    border-left: 0px;
    border-right: 0px;
    font-family: Montserrat;
font-weight: 36.55em;
font-size: 3.65em;

/* identical to box height, or 53px */

text-align: center;
letter-spacing: -0.02em;

color: #3C3C3A;

mix-blend-mode: normal;
    
}

.cartelDerecha{
    position: absolute;
    top: 1.24em;
    left: 50.58em;
    width: 3.51em;
    height: 3.51em;
    border: 0.29em dashed #767676;
    color: #3C3C3A;
    transform: rotate(55deg) skewX(20deg);
    border-top: 0px;
    border-right: 0px;

}

.condicion{

    width: 37em;
    height: 7.24em;
    margin-top: 0.77em;
    margin-bottom: 0.77em;
    
    border: 5px solid rgba(118, 118, 118, 0.24);
    box-sizing: border-box;
    border-radius: 1.46em;
    position: relative;
    
display: flex;
align-items: center;
}

.circulo{
    position: absolute;
width: 3.87em;
height: 3.87em;
left: 1.46em;
top: 1.46em;
border-radius: 50%;
background: #C4C4C4;
display: flex;
justify-content: center;
align-items: center;
}

.texto{
    font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 1.32em;
line-height: 105.4%;
/* identical to box height, or 19px */

letter-spacing: -0.02em;

color: #3C3C3A;

mix-blend-mode: normal;
margin-left: 6.21em;
}

.cosoMedio{
    height: 7.24em;
    justify-content: center;
    display: flex;
    align-items: center;
    position:relative;
    width: 9.72em;
    margin-top: 0.77em;
    margin-bottom: 0.77em;

}

.lineaCosoMedio{
    width: 9.72em;
height: 0em;


border: 0.4em dashed rgba(118, 118, 118, 0.24);
position: absolute;


}
.circuloCosoMedio{
    width: 2.27em;
height: 2.27em;
background: #ffffff;
position: absolute;
z-index: 1;
border-radius: 50%;
border: 3px solid rgba(118, 118, 118, 0.48);

}

.divisor{
    height: 7.24em;
    background-color: azul;
    display: flex;
    margin-top: 0.77em;
    margin-bottom: 0.77em;
}

.cartelAbajo{
    position: absolute;
width: 25.25em;
height: 3.95em;
left: 15.03em;
top: 25.96em;

background: rgba(196, 196, 196, 0.23);
border-radius: 1.99em;

font-family: Montserrat;
font-style: normal;
font-weight: 600;
font-size: 1.83em;
line-height: 105.4%;
/* or 32px */

text-align: center;
letter-spacing: -0.02em;

color: #3C3C3A;

mix-blend-mode: normal;
padding: 0.8em;

}

@media screen and (max-width: 900px) {
    .contenedor{
        font-size: 1.8vw;
    }
 
    .contenedorCondiciones{

        grid-template-columns: repeat(1, 1fr);
   
   }
   .cartel{
font-size: 0.7em;
left: 15.58vw;


}
.cosoMedio{

    display: none;
}

.cartelAbajo{
    position: absolute;

left: 2em;
top: 45em;
}
}